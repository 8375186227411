<template>
  <div
    data-testid="vonage-home-page-call-to-action-section"
    class="lg:h-[342px] relative select-none mt-[98px] lg:mt-0"
  >
    <div class="absolute bottom-0 left-0 right-0 h-[148px] bg-purple-950 hidden lg:block"></div>
    <div class="relative h-full w-full flex flex-row justify-center">
      <div class="w-full max-w-[1101px] relative mx-8">
        <div class="h-full p-6 lg:pl-[137px] lg:pt-[75px] lg:pb-[74px] flex">
          <div
            class="relative w-full h-full bg-gradient-to-r from-purple-800 to-purple-600 rounded-lg rounded-tl-[80px] rounded-br-[80px] lg:rounded-br-lg medium:p-8 small:p-8 px-12 pb-8 pt-[110px] lg:pl-[205px] lg:py-[38px] lg:pr-[38px] flex flex-col lg:flex-row medium:flex-row small:flex-row gap-4 lg:gap-6 xl:gap-12"
          >
            <StoryblokImage class="hidden medium:block small:block w-[260px] h-[228px]" :image="blok.image" />
            <div class="flex flex-col lg:flex-row medium:flex-col medium:items-start small:items-start">
              <div class="message text-canvas w-full lg:w-[462px]">
                <RichText :text="message" />
              </div>
              <div
                class="h-full flex flex-col mt-8 lg:mt-0 small:flex-row medium:flex-row justify-center min-w-[160px]"
              >
                <a :href="buttonHref" type="button">
                  <VButton :label="buttonLabel" icon-trailing appearance="filled" class="vvd-theme-alternate"></VButton>
                </a>
              </div>
            </div>
          </div>
        </div>
        <img
          class="absolute small:hidden medium:hidden top-[-98px] left-[48px] lg:top-0 lg:left-0 lg:bottom-0 w-[260px] h-[228px] lg:w-[342px] lg:h-[342px]"
          :src="blok.image.filename"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { VonageHomePageCallToActionSection } from '@/types/storyblok';
import { VButton } from '@vonage/vivid-vue';
import { useUserStore } from '@/store/user';
import { isUserLoggedIn } from '@/utils/user';
import { VonageRegistrationUrl } from '@/constants';
import { useBrandingStore } from '@/store/branding';
import RichText from '@/components/rich-text/RichText.vue';
import StoryblokImage from '@/components/storyblok/StoryblokImage.vue';

const props = defineProps({
  blok: { type: Object as PropType<VonageHomePageCallToActionSection>, required: true },
});

const user = useUserStore();
const branding = useBrandingStore();

const loggedIn = isUserLoggedIn(user.info as API.UserInfo);

const message = computed(() => (loggedIn ? props.blok?.loggedInUserMessage : props.blok?.anonymousUserMessage));
const buttonLabel = computed(() =>
  loggedIn ? props.blok?.loggedInUserButtonLabel : props.blok?.anonymousUserButtonLabel
);
const buttonHref = computed(() => (loggedIn ? branding.data?.customerDashboardUrl : VonageRegistrationUrl));
</script>

<style scoped lang="scss">
.message {
  :deep(h2) {
    margin: 0;
  }
  :deep(h4) {
    margin-block: 0;
    padding-top: 8px;
    font-weight: 300;
  }
  :deep(p) {
    margin-block: 0;
    padding-top: 8px;
    font-weight: 200;
  }
}
</style>
